.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
  }
  
  .react-tagsinput--focused {
    border: 2px solid;
    border-color: #3F51B5;
  }
  
  .react-tagsinput-tag {
    background-color: #B8C6D6;
    border-radius: 5px;
    border: 3px solid #B8C6D6;
    color: #344C67;
    display: inline-block;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }
  
  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }
  
  .react-tagsinput-tag a::before {
    content: " X";
  }
  
  .react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 350px;
  }

  .tagsInputLabel {
    color: #B8C6D6;
    font-weight: normal;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-top: 5px;
    font-size: 13px;
  }