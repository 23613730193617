 .icon {
    cursor: pointer !important;
    background: #d9dfe6 !important;
    padding-left: 24px !important;
    text-align: left !important;
  }
  
  .cell {
    cursor: pointer !important;
    background-color: #d9dfe6 !important;
  }
  
  .instance-cell {
    padding-left: 60px !important;
    text-align: left !important;
  }
  
  .switch-cell {
    background-color: #d9dfe6 !important;
    text-align: center !important;
    vertical-align: middle !important;
  }
  
  .instance-switch-cell {
    text-align: center !important;
    vertical-align: middle !important;
  }
  
  .tooltip {
    height: 18px !important;
    padding: 0 !important;
    margin: 0 auto !important;
  }
  
  .Expand {
    vertical-align: middle !important;
  }
  