.recharts-default-tooltip {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-transform: capitalize;
}

.recharts-tooltip-label {
    font-weight: 600;    
}

.recharts-surface {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

path.recharts-curve {
    stroke-width: 2;
}

.small {
    font-size: 28px !important;
}

.recharts-cartesian-axis-ticks {
    font-weight: 600;
}

.interface-percentage {
    color: #A1C181;
}

.last-login {
    color: #619B8A;
}

.company-users {
    color: #FE7F2D;
}

.card-row {
    display: flex;
    height: 50%;
    justify-content: space-between;
}

.card-item {
    width: 33%;
    padding: 5px;
}

.card-icon {
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 30px !important; 
}

.recharts-legend-item {
    margin-right: 30px !important;
}